import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentButton.figmaUrl.android} codeUrl={checklists.componentButton.codeUrl.android} checklists={checklists.componentButton.checklists} mdxType="ChecklistResourcesSection" />
    <h2>{`Usage`}</h2>
    <p>{`To implement Button, you can use this tag`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Button } from "@legion-crossplatform/ui";
`}</code></pre>
    <p>{`Buttons are commonly used in user interfaces to initiate an action or prompt the user to open a link. They can contain a combination of clear and concise labels as well as icons to provide additional context.
Legion provides a variety of options to customize your buttons to match your desired aesthetic and functionality.`}</p>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`Legion has various color themes:`}</p>
    <ol>
      <li parentName="ol">{`Color Primary Theme.`}</li>
      <li parentName="ol">{`Color Secondary Theme.`}</li>
      <li parentName="ol">{`Color Tertiary Theme.`}</li>
      <li parentName="ol">{`Color Warning Theme.`}</li>
      <li parentName="ol">{`Color Success Theme.`}</li>
      <li parentName="ol">{`Color Error Theme.`}</li>
    </ol>
    <p>{`In this page we will demonstrate the usage using the primary variant. Each theme has four variants:`}</p>
    <div className="divi" />
    <h3>{`Solid`}</h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 150,
            "height": 75,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/react-native/react-native-button-solid-1.png",
            "alt": "Button Legion React Native"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Button } from "@legion-crossplatform/ui";

<Button variant="primary">Button</Button>

// or you can just use the default variant
<Button>Solid primary</Button>
`}</code></pre>
    <h3>{`Soft`}</h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 150,
            "height": 75,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/react-native/react-native-button-soft-1.png",
            "alt": "Button Legion React Native"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Button } from "@legion-crossplatform/ui";

<Button variant="softPrimary">Button</Button>
`}</code></pre>
    <h3>{`Outline`}</h3>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 150,
            "height": 75,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/button/react-native/react-native-button-outline-1.png",
            "alt": "Button Legion React Native"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Button } from "@legion-crossplatform/ui";

<Button variant="outlinePrimary">Button</Button>
`}</code></pre>
    <h3>{`Transparent`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Button } from "@legion-crossplatform/ui";

<Button variant="transparentPrimary">Button</Button>
`}</code></pre>
    <div className="divi" />
    <h2>{`Icon`}</h2>
    <p>{`You can set the button to have an icon to the left or right side of the text.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Button } from "@legion-crossplatform/ui";
import { Activity, Airplay } from '@tamagui/lucide-icons'

// Add icon before text
<Button icon={Activity} size="$3">
  IconBefore
</Button>

//Add icon after text
<Button iconAfter={Activity} size="$3">
  IconAfter
</Button>
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`circular`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Makes the button rounded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`JSX.Element`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pass any React element, appears before text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconAfter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`JSX.Element`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pass any React element, appears after text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`noTextWrap`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If true, button won’t wrap content with a Text element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`scaleIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Scale the icon more than the default value by this number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`scaleSpace`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Scale the spacing more than the default value by this number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`spaceFlex`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Makes all space elements have Flex display rule`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}{`|`}{` `}<inlineCode parentName="td">{`tokens.size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set a size, number, or one of the size token values`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      